import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
declare const startBackground, sliderStarter: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  private data:any = []
  constructor(private http: HttpClient) {
   
  }

  ngOnInit() {
    startBackground();
    sliderStarter();
  }
}

import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Bilişim Küpü';
  previousUrl: string;


  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          const currentUrlSlug = event.url.slice(1);
          if (currentUrlSlug) {
            this.renderer.removeClass(document.body, "bk-main-screen");
            this.renderer.addClass(document.body, "bk-content-screen");
          } else {
            this.renderer.addClass(document.body, "bk-main-screen");
            this.renderer.removeClass(document.body, "bk-content-screen");
          }
          this.previousUrl = currentUrlSlug;
        }
      });


  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const jsonPath = '/assets/data/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  getPageContents(params:any) {
    var thePage:string;
    var theSubject:string;
    var theSolution:string;
    
    if(params.page){
      thePage = '/'+params.page;
    }else{
      thePage = '';
    }

    if(params.subject){
      theSubject = '/'+params.subject;
    }else{
      theSubject = '';
    }

    if(params.solution){
      theSolution = '/'+params.solution;
    }else{
      theSolution = '';
    }

    console.log(jsonPath + '' + thePage + '' +theSubject+ '' +theSolution+ '.json');
    return this.http.get(jsonPath + '' + thePage + '' +theSubject+ '' +theSolution+ '.json');
  }
}

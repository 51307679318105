import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
declare const  buildContactForm: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {

  constructor( private api: ApiService, private sanitizer: DomSanitizer) { }
  contactInfos: any = [];
  requestParams: any ={
    page:"contact"
  };

  ngOnInit() {
    this.api.getPageContents(this.requestParams).subscribe((data)=>{
      this.contactInfos = data;
    });
    buildContactForm();
  }
  
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: ':page/:subject',
    component: DetailComponent,
    children: [
      {
        path: ':solution',
        component: DetailComponent
      }
    ]
  },
  { path: 'solutions',
    redirectTo: '/solutions/cybersecurity',
    pathMatch: 'full' 
  },
  { path: 'products',
    redirectTo: '/products/kalgan',
    pathMatch: 'full' 
  },
  { path: 'about',
    redirectTo: '/about/who',
    pathMatch: 'full' 
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

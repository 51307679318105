import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import {Router, ActivatedRoute, Params} from '@angular/router';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private api: ApiService) {}
  public page: string;
  public subject: string;
  contents: any = [];

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.api.getPageContents(routeParams).subscribe((data)=>{
        this.page = routeParams.page;
        this.subject = routeParams.subject;
        this.contents = data;
      })
    });
    
  }

}
